import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layouts/layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Image = makeShortcode("Image");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`After a few years of wearing my wireless headphones consistently, I reached the point where the battery wasn't holding a charge anymore. It could reach 25% at most, and so I'd need to swap it out.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px",
          "marginBottom": "1rem"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "74.84662576687117%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAUCBP/EABQBAQAAAAAAAAAAAAAAAAAAAAH/2gAMAwEAAhADEAAAAZ6lkZLrJ//EABsQAAEEAwAAAAAAAAAAAAAAAAIAAQMSEBQi/9oACAEBAAEFAhhclIAjgZApr81Zl//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABoQAAMAAwEAAAAAAAAAAAAAAAABERIhIjH/2gAIAQEABj8COXkekSe0Wkh//8QAGxAAAwEBAAMAAAAAAAAAAAAAAAERMSFBYXH/2gAIAQEAAT8hfWc1+kPkvqwWcgRa3CjqHhhtIf/aAAwDAQACAAMAAAAQQ8//xAAWEQADAAAAAAAAAAAAAAAAAAAQEVH/2gAIAQMBAT8QUH//xAAVEQEBAAAAAAAAAAAAAAAAAAAAEf/aAAgBAgEBPxCo/8QAGxABAQACAwEAAAAAAAAAAAAAAREAITFRYYH/2gAIAQEAAT8QAwJov0cBgE2CBxyEHVmCGWFOZO8lN3t6w2mR5c//2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "overhead shot of headphones",
          "title": "overhead shot of headphones",
          "src": "/static/457338f3307e5b117bae51aaadd102b3/6aca1/establishing_shot.jpg",
          "srcSet": ["/static/457338f3307e5b117bae51aaadd102b3/d2f63/establishing_shot.jpg 163w", "/static/457338f3307e5b117bae51aaadd102b3/c989d/establishing_shot.jpg 325w", "/static/457338f3307e5b117bae51aaadd102b3/6aca1/establishing_shot.jpg 650w", "/static/457338f3307e5b117bae51aaadd102b3/7c09c/establishing_shot.jpg 975w", "/static/457338f3307e5b117bae51aaadd102b3/01ab0/establishing_shot.jpg 1300w", "/static/457338f3307e5b117bae51aaadd102b3/d2602/establishing_shot.jpg 4032w"],
          "sizes": "(max-width: 650px) 100vw, 650px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`After buying a replacement battery on eBay (mine cost `}<span>{`$`}</span>{`23), I got to work.`}</p>
    <p>{`If you are attempting this repair on your own, be sure to open the `}<strong parentName="p">{`left`}</strong>{` side, which does not have the touch sensor or the charging port.`}</p>
    <p>{`Tools I ended up using:`}</p>
    <ul>
      <li parentName="ul">{`Phillips-head screwdriver`}</li>
      <li parentName="ul">{`Tweezers`}</li>
      <li parentName="ul">{`Plastic spudger (wide, flat-head plastic tool often used to safely pry electronics apart)`}</li>
      <li parentName="ul">{`Hair dryer`}</li>
      <li parentName="ul">{`Credit card`}</li>
      <li parentName="ul">{`A knife (sharp, metal tool used to recklessly pry electronics apart)`}</li>
      <li parentName="ul">{`Double-sided tape`}</li>
    </ul>
    <h2 {...{
      "id": "step-1---ear-pads"
    }}><a parentName="h2" {...{
        "href": "#step-1---ear-pads"
      }}>{`Step 1 - Ear Pads`}</a></h2>
    <p>{`You'll need to start by prying off the ear pad (also a great part to replace, if you're extending the life of your headphones). It takes a somewhat concerning amount of force, but they're designed to come off that way, so don't worry too much. I had good luck by placing my thumbs inside the cup at the narrow end and pushing out until the part popped off.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px",
          "marginBottom": "1rem"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "74.84662576687117%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAQFA//EABUBAQEAAAAAAAAAAAAAAAAAAAEC/9oADAMBAAIQAxAAAAGZpUUlmDYn/8QAGRAAAwEBAQAAAAAAAAAAAAAAAAECEQMh/9oACAEBAAEFApgceYcYTm8Rh//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABgQAAIDAAAAAAAAAAAAAAAAAAEQESAx/9oACAEBAAY/AqQdX//EABoQAQEAAwEBAAAAAAAAAAAAAAEAESExQXH/2gAIAQEAAT8hZ4R+N/ZnQ4yTHWh7Bb//2gAMAwEAAgADAAAAEOgv/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPxA//8QAFREBAQAAAAAAAAAAAAAAAAAAEBH/2gAIAQIBAT8Qp//EABsQAQADAQADAAAAAAAAAAAAAAEAEUEhMWGR/9oACAEBAAE/EOmnyNug08I+zJQpFw2IWHiJbWADMg1gVP/Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "cups removed",
          "title": "cups removed",
          "src": "/static/459bd22514825de34c2e4b328aba401b/6aca1/cup_removed.jpg",
          "srcSet": ["/static/459bd22514825de34c2e4b328aba401b/d2f63/cup_removed.jpg 163w", "/static/459bd22514825de34c2e4b328aba401b/c989d/cup_removed.jpg 325w", "/static/459bd22514825de34c2e4b328aba401b/6aca1/cup_removed.jpg 650w", "/static/459bd22514825de34c2e4b328aba401b/7c09c/cup_removed.jpg 975w", "/static/459bd22514825de34c2e4b328aba401b/01ab0/cup_removed.jpg 1300w", "/static/459bd22514825de34c2e4b328aba401b/d2602/cup_removed.jpg 4032w"],
          "sizes": "(max-width: 650px) 100vw, 650px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <h2 {...{
      "id": "step-2---screws"
    }}><a parentName="h2" {...{
        "href": "#step-2---screws"
      }}>{`Step 2 - Screws`}</a></h2>
    <p>{`Next, you'll see 6 round stickers that are covering the screw holes. You'll need to remove them, which isn't very difficult with a pair of tweezers.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px",
          "marginBottom": "1rem"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "74.84662576687117%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAQBAgX/xAAVAQEBAAAAAAAAAAAAAAAAAAACAf/aAAwDAQACEAMQAAABlCzRWaOlP//EABoQAAICAwAAAAAAAAAAAAAAAAECABESEyH/2gAIAQEAAQUCcpj0Qs9rbvrhUT//xAAVEQEBAAAAAAAAAAAAAAAAAAABEP/aAAgBAwEBPwFn/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAGhABAAMAAwAAAAAAAAAAAAAAAQAREhAhMf/aAAgBAQAGPwLLSyxSds0+8//EABoQAAIDAQEAAAAAAAAAAAAAAAABESExUWH/2gAIAQEAAT8hTwjUrEn2w1iRJQH6h8J/T//aAAwDAQACAAMAAAAQKz//xAAWEQADAAAAAAAAAAAAAAAAAAABEBH/2gAIAQMBAT8QKr//xAAWEQADAAAAAAAAAAAAAAAAAAAAARH/2gAIAQIBAT8QShT/xAAcEAEBAAIDAQEAAAAAAAAAAAABEQAhMVFxQfD/2gAIAQEAAT8QRmyQ/wCOKeQgugwyI3hkcKddB8J1hjIlXmejiJazVmf/2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "sticker removed",
          "title": "sticker removed",
          "src": "/static/eada46f883ad67d5e987ba801c25e91a/6aca1/removing_stickers.jpg",
          "srcSet": ["/static/eada46f883ad67d5e987ba801c25e91a/d2f63/removing_stickers.jpg 163w", "/static/eada46f883ad67d5e987ba801c25e91a/c989d/removing_stickers.jpg 325w", "/static/eada46f883ad67d5e987ba801c25e91a/6aca1/removing_stickers.jpg 650w", "/static/eada46f883ad67d5e987ba801c25e91a/7c09c/removing_stickers.jpg 975w", "/static/eada46f883ad67d5e987ba801c25e91a/01ab0/removing_stickers.jpg 1300w", "/static/eada46f883ad67d5e987ba801c25e91a/d2602/removing_stickers.jpg 4032w"],
          "sizes": "(max-width: 650px) 100vw, 650px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`After you've removed them, remove the 6 screws. You should have 2 longer screws at the base, and four black screws at the head of the headphones.`}</p>
    <h2 {...{
      "id": "opening-it-up"
    }}><a parentName="h2" {...{
        "href": "#opening-it-up"
      }}>{`Opening it up`}</a></h2>
    <p>{`To open the headphones up, you will need to pry the cup open, which has some light glue holding it together in addition to the screws. You'll need to get in between the silver and black plastic with something. Ideally you'll use a very professional metal spudger for this.`}</p>
    <Image relativePath="pxc_repair/prying_open.jpg" mdxType="Image">
  but your letter-opening knife works in a pinch
    </Image>
    <p>{`Once you've got the parts separated, work your way around to loosen the top. I used a plastic spudger once I had enough space to fit it in.`}</p>
    <p>{`Next, you'll have to carefully open the cup. There are two sets of wires, and the short cable is fastened down. You'll need to carefully pull the short cable out of its grip before you can open it.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px",
          "marginBottom": "1rem"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "74.84662576687117%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAQCAwX/xAAWAQEBAQAAAAAAAAAAAAAAAAABAAL/2gAMAwEAAhADEAAAAUR+nLnkif/EAB0QAAICAQUAAAAAAAAAAAAAAAABAgMREhMhIjH/2gAIAQEAAQUC4RtOY+rhRqV2Uen/xAAWEQEBAQAAAAAAAAAAAAAAAAAAARL/2gAIAQMBAT8Bar//xAAVEQEBAAAAAAAAAAAAAAAAAAAAEf/aAAgBAgEBPwFH/8QAGxAAAQQDAAAAAAAAAAAAAAAAAQAQEXEhQVH/2gAIAQEABj8C2KC5aggNmG//xAAbEAACAgMBAAAAAAAAAAAAAAABEQAhMUFhUf/aAAgBAQABPyF7WQy2iYezLxgjk5e4JkaeC4UVz//aAAwDAQACAAMAAAAQ4y//xAAWEQEBAQAAAAAAAAAAAAAAAAAAETH/2gAIAQMBAT8QQx//xAAYEQACAwAAAAAAAAAAAAAAAAAAARFBYf/aAAgBAgEBPxBzRo//xAAdEAACAgIDAQAAAAAAAAAAAAABEQAhMUFRYYHB/9oACAEBAAE/EA4wNhEnpuobcOAg041JQ0L9hLyCoPIj0Gj6l4hOMT//2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Opened up",
          "title": "Opened up",
          "src": "/static/d3202cd09e0d96eef70b010997f34131/6aca1/opened_up.jpg",
          "srcSet": ["/static/d3202cd09e0d96eef70b010997f34131/d2f63/opened_up.jpg 163w", "/static/d3202cd09e0d96eef70b010997f34131/c989d/opened_up.jpg 325w", "/static/d3202cd09e0d96eef70b010997f34131/6aca1/opened_up.jpg 650w", "/static/d3202cd09e0d96eef70b010997f34131/7c09c/opened_up.jpg 975w", "/static/d3202cd09e0d96eef70b010997f34131/01ab0/opened_up.jpg 1300w", "/static/d3202cd09e0d96eef70b010997f34131/d2602/opened_up.jpg 4032w"],
          "sizes": "(max-width: 650px) 100vw, 650px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`Note the short cable on the bottom side of the photo.`}</p>
    <h2 {...{
      "id": "removing-the-battery"
    }}><a parentName="h2" {...{
        "href": "#removing-the-battery"
      }}>{`Removing the battery`}</a></h2>
    <p>{`From there, you will need to detach the cables. The ribbon cable requires you to raise the black tabs, and should slide out effortlessly once they are open. The three-wire connector is a bit awkward to get at, but lifts straight out without any gotchas.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px",
          "marginBottom": "1rem"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "74.84662576687117%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAGQAAAgMBAAAAAAAAAAAAAAAAAAMBAgQF/8QAFgEBAQEAAAAAAAAAAAAAAAAAAAEC/9oADAMBAAIQAxAAAAHnaLzmpHB//8QAGhAAAgMBAQAAAAAAAAAAAAAAAQIAAxIRE//aAAgBAQABBQLdinDtPGyUgNC3Io0P/8QAFREBAQAAAAAAAAAAAAAAAAAAABH/2gAIAQMBAT8BV//EABYRAQEBAAAAAAAAAAAAAAAAAAABEf/aAAgBAgEBPwFlf//EABwQAQACAQUAAAAAAAAAAAAAAAEAEQIQIjJRYf/aAAgBAQAGPwKhm9DR7lZ2Pk5M/8QAGxABAAMAAwEAAAAAAAAAAAAAAQARITFBUYH/2gAIAQEAAT8h1gRDXxIg0bEG8nJfxGX2lojF+T//2gAMAwEAAgADAAAAEOMf/8QAGBEAAgMAAAAAAAAAAAAAAAAAAAERUWH/2gAIAQMBAT8QWkKP/8QAGhEAAgIDAAAAAAAAAAAAAAAAAAERMUGh4f/aAAgBAgEBPxBzgUF66f/EABsQAQADAQEBAQAAAAAAAAAAAAEAESExgbHw/9oACAEBAAE/EMEzwGx8ZrCnED7UdWEy7qLBpLFg1jHWtBIIF/dhEwQLr7P/2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "cables removed",
          "title": "cables removed",
          "src": "/static/ad79cb8510b7656f121d4e788a9a0e05/6aca1/connectors_open.jpg",
          "srcSet": ["/static/ad79cb8510b7656f121d4e788a9a0e05/d2f63/connectors_open.jpg 163w", "/static/ad79cb8510b7656f121d4e788a9a0e05/c989d/connectors_open.jpg 325w", "/static/ad79cb8510b7656f121d4e788a9a0e05/6aca1/connectors_open.jpg 650w", "/static/ad79cb8510b7656f121d4e788a9a0e05/7c09c/connectors_open.jpg 975w", "/static/ad79cb8510b7656f121d4e788a9a0e05/01ab0/connectors_open.jpg 1300w", "/static/ad79cb8510b7656f121d4e788a9a0e05/d2602/connectors_open.jpg 4032w"],
          "sizes": "(max-width: 650px) 100vw, 650px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`Both connectors removed`}</p>
    <p>{`Now you've got to remove the battery. I used my spudger to get under it and carefully worked the glue apart until the battery popped out. Note: `}<strong>{`Do not use metal tools to pry a battery off.`}</strong>{` It can puncture the battery and start a fire.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px",
          "marginBottom": "1rem"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "74.84662576687117%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAQFA//EABYBAQEBAAAAAAAAAAAAAAAAAAEAAv/aAAwDAQACEAMQAAABUWomWYbk/wD/xAAZEAACAwEAAAAAAAAAAAAAAAABAgADERL/2gAIAQEAAQUCKqIK+mwRELFq+Y2Fv//EABURAQEAAAAAAAAAAAAAAAAAAAAR/9oACAEDAQE/AVf/xAAVEQEBAAAAAAAAAAAAAAAAAAAQEf/aAAgBAgEBPwGn/8QAGxABAQABBQAAAAAAAAAAAAAAAQAREBIiMaH/2gAIAQEABj8CQbB7puC5zjq//8QAHRABAAICAgMAAAAAAAAAAAAAAQARMVEhYUFxgf/aAAgBAQABPyFWy7Wr+RQ4L9Ii0mNMoIeoDHDULArwn//aAAwDAQACAAMAAAAQ1N//xAAXEQADAQAAAAAAAAAAAAAAAAAAESGR/9oACAEDAQE/EEyJMP/EABcRAQADAAAAAAAAAAAAAAAAAAABETH/2gAIAQIBAT8QmjX/xAAdEAEBAAIBBQAAAAAAAAAAAAABEQAhMUFRcZGx/9oACAEBAAE/EJGCo0h4c5J58ag94qUql0ucDKlMl6/cmQ3sNy5MdOxn/9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "battery removed",
          "title": "battery removed",
          "src": "/static/9bb80b5698a8f626ba86e4010bf7f958/6aca1/battery_removed.jpg",
          "srcSet": ["/static/9bb80b5698a8f626ba86e4010bf7f958/d2f63/battery_removed.jpg 163w", "/static/9bb80b5698a8f626ba86e4010bf7f958/c989d/battery_removed.jpg 325w", "/static/9bb80b5698a8f626ba86e4010bf7f958/6aca1/battery_removed.jpg 650w", "/static/9bb80b5698a8f626ba86e4010bf7f958/7c09c/battery_removed.jpg 975w", "/static/9bb80b5698a8f626ba86e4010bf7f958/01ab0/battery_removed.jpg 1300w", "/static/9bb80b5698a8f626ba86e4010bf7f958/d2602/battery_removed.jpg 4032w"],
          "sizes": "(max-width: 650px) 100vw, 650px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`It took some wiggling to get the tweezers underneath`}</p>
    <p>{`You'll want to keep the orange antenna that's glued to the old battery, so here I blasted it for a little while with my hair dryer to get the glue to relax.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px",
          "marginBottom": "1rem"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "74.84662576687117%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAIDBP/EABUBAQEAAAAAAAAAAAAAAAAAAAEC/9oADAMBAAIQAxAAAAHOs3lqbQf/xAAaEAEAAwADAAAAAAAAAAAAAAACAQMRABIh/9oACAEBAAEFAkZ2FbkU9oY9rKjgWH//xAAVEQEBAAAAAAAAAAAAAAAAAAAAEf/aAAgBAwEBPwGI/8QAFhEBAQEAAAAAAAAAAAAAAAAAAQAR/9oACAECAQE/AVLb/8QAGRAAAwEBAQAAAAAAAAAAAAAAAAERISIx/9oACAEBAAY/ArjI28KcMh4f/8QAGhABAAMBAQEAAAAAAAAAAAAAAQARIWExgf/aAAgBAQABPyG4+gYlUZ8ubAjugOs0Ft9mI0n/2gAMAwEAAgADAAAAEHQP/8QAFhEBAQEAAAAAAAAAAAAAAAAAEQAh/9oACAEDAQE/EG7K/8QAFhEBAQEAAAAAAAAAAAAAAAAAABEh/9oACAECAQE/EIMS/8QAGhABAQEBAQEBAAAAAAAAAAAAAREAIUExcf/aAAgBAQABPxAi6pPmXQxZeAHnMhULo8CFO1dQSnp3Z+5yejyjv//Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "using a hairdryer",
          "title": "using a hairdryer",
          "src": "/static/015fd24bb9f09c8ade85a579726ad1d4/6aca1/hairdryer.jpg",
          "srcSet": ["/static/015fd24bb9f09c8ade85a579726ad1d4/d2f63/hairdryer.jpg 163w", "/static/015fd24bb9f09c8ade85a579726ad1d4/c989d/hairdryer.jpg 325w", "/static/015fd24bb9f09c8ade85a579726ad1d4/6aca1/hairdryer.jpg 650w", "/static/015fd24bb9f09c8ade85a579726ad1d4/7c09c/hairdryer.jpg 975w", "/static/015fd24bb9f09c8ade85a579726ad1d4/01ab0/hairdryer.jpg 1300w", "/static/015fd24bb9f09c8ade85a579726ad1d4/d2602/hairdryer.jpg 4032w"],
          "sizes": "(max-width: 650px) 100vw, 650px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`After warming the antenna, I used my plastic spudger to get the edges of the antenna up, and then used a credit card to separate it from the battery. Take your time and be careful.`}</p>
    <h2 {...{
      "id": "reassembly"
    }}><a parentName="h2" {...{
        "href": "#reassembly"
      }}>{`Reassembly`}</a></h2>
    <p>{`Once you have the antenna removed, you'll want to stick it on your new battery. For me, there was enough glue left on to reattach it with no problems.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px",
          "marginBottom": "1rem"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "74.84662576687117%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAIEA//EABUBAQEAAAAAAAAAAAAAAAAAAAEC/9oADAMBAAIQAxAAAAFMI6IZxyj/xAAaEAACAwEBAAAAAAAAAAAAAAABAwACEhEh/9oACAEBAAEFAj6XLyOSrD2ztV1P/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFhEAAwAAAAAAAAAAAAAAAAAAEBEh/9oACAECAQE/Aax//8QAGRABAAIDAAAAAAAAAAAAAAAAAQAQERIh/9oACAEBAAY/AuQaGY1r/8QAGRAAAgMBAAAAAAAAAAAAAAAAAREAITEQ/9oACAEBAAE/IUGEDqXgC95E0KDBUi94/9oADAMBAAIAAwAAABBfP//EABYRAQEBAAAAAAAAAAAAAAAAABEBEP/aAAgBAwEBPxBhn//EABcRAQADAAAAAAAAAAAAAAAAAAEQETH/2gAIAQIBAT8QShMj/8QAGhABAAMBAQEAAAAAAAAAAAAAAQARIZFBUf/aAAgBAQABPxBFiM6jF6IhveQSRcNmNfaitB0CV7A+FE//2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "antenna on new battery",
          "title": "antenna on new battery",
          "src": "/static/2364f389c6f392e71f3fef0eaebcf6f3/6aca1/new_battery.jpg",
          "srcSet": ["/static/2364f389c6f392e71f3fef0eaebcf6f3/d2f63/new_battery.jpg 163w", "/static/2364f389c6f392e71f3fef0eaebcf6f3/c989d/new_battery.jpg 325w", "/static/2364f389c6f392e71f3fef0eaebcf6f3/6aca1/new_battery.jpg 650w", "/static/2364f389c6f392e71f3fef0eaebcf6f3/7c09c/new_battery.jpg 975w", "/static/2364f389c6f392e71f3fef0eaebcf6f3/01ab0/new_battery.jpg 1300w", "/static/2364f389c6f392e71f3fef0eaebcf6f3/d2602/new_battery.jpg 4032w"],
          "sizes": "(max-width: 650px) 100vw, 650px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`New battery with antenna`}</p>
    <p>{`You'll want to fasten the new battery back in the place of the original. If you're fancy you might have glue for this, but I just applied some moderately strong double-sided tape.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px",
          "marginBottom": "1rem"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "133.12883435582822%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAbABQDASIAAhEBAxEB/8QAGAABAQEBAQAAAAAAAAAAAAAAAAUEAQP/xAAWAQEBAQAAAAAAAAAAAAAAAAABAAL/2gAMAwEAAhADEAAAAZXNG3DGUDWZAPi2F//EAB4QAAICAgIDAAAAAAAAAAAAAAECABEDEgQhEyIx/9oACAEBAAEFAtbmsqU0T1XJqXtArmz4nmY98f6T3//EABYRAAMAAAAAAAAAAAAAAAAAAAAQEf/aAAgBAwEBPwFw/8QAGBEAAwEBAAAAAAAAAAAAAAAAAAERAkH/2gAIAQIBAT8B04J1E6U//8QAGxAAAwACAwAAAAAAAAAAAAAAAAERECEiMVH/2gAIAQEABj8C8NZUiOIq0RbOiDx//8QAHBAAAwEAAgMAAAAAAAAAAAAAAAERITFRQWGB/9oACAEBAAE/IWj2BlRpq3o5p2UVpxCmSwuvaN5B+p9GLTDlDvI//9oADAMBAAIAAwAAABCb77D/xAAXEQEBAQEAAAAAAAAAAAAAAAABEQBR/9oACAEDAQE/EA7kBmkZp3//xAAZEQEAAgMAAAAAAAAAAAAAAAABABARITH/2gAIAQIBAT8QXiMC6oyn/8QAHRABAAICAwEBAAAAAAAAAAAAAQARIUExUWFx8P/aAAgBAQABPxCiUKBFyu2ZqC/CUajzcJgnP3yZPJCTapzCOsq9ZhVDnZ0dS8LVd/zLNp8M10jqQCrKGF1L/Kf/2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "battery in place",
          "title": "battery in place",
          "src": "/static/5889e303f2ac418a21e9c448f7aa5207/6aca1/tape.jpg",
          "srcSet": ["/static/5889e303f2ac418a21e9c448f7aa5207/d2f63/tape.jpg 163w", "/static/5889e303f2ac418a21e9c448f7aa5207/c989d/tape.jpg 325w", "/static/5889e303f2ac418a21e9c448f7aa5207/6aca1/tape.jpg 650w", "/static/5889e303f2ac418a21e9c448f7aa5207/7c09c/tape.jpg 975w", "/static/5889e303f2ac418a21e9c448f7aa5207/01ab0/tape.jpg 1300w", "/static/5889e303f2ac418a21e9c448f7aa5207/9568a/tape.jpg 3024w"],
          "sizes": "(max-width: 650px) 100vw, 650px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span>{`
Don't judge me`}</p>
    <p>{`From there, it's just a classic matter of reversing through the steps!`}</p>
    <ol>
      <li parentName="ol">{`Stick the new battery in`}</li>
      <li parentName="ol">{`Attach the connectors`}</li>
      <li parentName="ol">{`Replace the cup (don't forget to secure the short wire)`}</li>
      <li parentName="ol">{`Screw everything in`}</li>
      <li parentName="ol">{`Reapply the stickers (optional)`}</li>
      <li parentName="ol">{`Snap the ear pads back into place`}</li>
    </ol>
    <p>{`And there you have it! You should have successfully given a new lease on life to your lovely pair of wireless headphones.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px",
          "marginBottom": "1rem"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "74.84662576687117%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAMFAQT/xAAVAQEBAAAAAAAAAAAAAAAAAAACAP/aAAwDAQACEAMQAAAB58W0umQiH//EABkQAAIDAQAAAAAAAAAAAAAAAAACAQMREv/aAAgBAQABBQJYhiyBLO0WyVG1zrD/xAAWEQEBAQAAAAAAAAAAAAAAAAAAARH/2gAIAQMBAT8BjH//xAAVEQEBAAAAAAAAAAAAAAAAAAABEP/aAAgBAgEBPwFn/8QAGhAAAwEAAwAAAAAAAAAAAAAAAAESEQIhMf/aAAgBAQAGPwJU9KSyRcjpEp6es//EABoQAQACAwEAAAAAAAAAAAAAAAEAIRExQXH/2gAIAQEAAT8hLrAoCCGW1vUCgylzCgV2LBdW1ycAPNT/2gAMAwEAAgADAAAAEJc//8QAFhEBAQEAAAAAAAAAAAAAAAAAEQAB/9oACAEDAQE/ECbkL//EABYRAQEBAAAAAAAAAAAAAAAAAAEAEf/aAAgBAgEBPxDQjbf/xAAcEAEAAwADAQEAAAAAAAAAAAABABEhMUFhUZH/2gAIAQEAAT8QLzYsOV4rz7H44JTW9VE0kSLMY6URTa0O6gpvKtKgSdDoTxn5P//Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "me wearing my headphones",
          "title": "me wearing my headphones",
          "src": "/static/9c5fb5c8a1561df1fa9d8dc682f77505/6aca1/headshot.jpg",
          "srcSet": ["/static/9c5fb5c8a1561df1fa9d8dc682f77505/d2f63/headshot.jpg 163w", "/static/9c5fb5c8a1561df1fa9d8dc682f77505/c989d/headshot.jpg 325w", "/static/9c5fb5c8a1561df1fa9d8dc682f77505/6aca1/headshot.jpg 650w", "/static/9c5fb5c8a1561df1fa9d8dc682f77505/7c09c/headshot.jpg 975w", "/static/9c5fb5c8a1561df1fa9d8dc682f77505/01ab0/headshot.jpg 1300w", "/static/9c5fb5c8a1561df1fa9d8dc682f77505/768c6/headshot.jpg 3264w"],
          "sizes": "(max-width: 650px) 100vw, 650px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      